.tableWrap {
  @include breakpoint(small only){
    overflow: auto;
    margin: 0 2%;
    padding: 0;
    width: 96%;
    .table {
      width: auto;
    }
  }
}
.table {
  &.price{
    th,td {
      background: $white;
      border: 1px solid #dfdfdf;
      text-align: left;
      padding: 1em 2em 1em 2.4em;
    }
    .head {
      background: $maincolor;
      color: $white;
    }
    @include breakpoint(small only){
      th,td {
        text-align: left;
        padding: .4em .6em;
      }
    }
    border-collapse: collapse;
    width: 100%;

  }
}



