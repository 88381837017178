// control/space
$contents: 60px;

$spaceL: 50px;
$spaceM: 30px;
$spaceS: 10px;
// /control/space

// control/font
.wf-sawarabigothic { font-family: "Sawarabi Gothic"; }
.wf-sawarabimincho { font-family: "Sawarabi Mincho"; }
.wf-mplus1p { font-family: "Mplus 1p"; }
.wf-noto { font-family: 'Noto Sans JP', sans-serif; }

@font-face {
  font-family: 'A1MinchoStd';

  src: url("../font/A-OTF-A1MinchoStd-Bold.woff") format("woff"),
    url("../font/A-OTF-A1MinchoStd-Bold.otf") format("opentype");
//    url("../font/GenShinGothic-P-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

.bold {
  font-weight: 700;
}

.wf-one {
  font-family: 'A1MinchoStd';
}
// /control/font

// no use
ul {
  list-style-type: none;
  margin: 0;
}
p {
  margin: 0;
  line-height: inherit;
}

@mixin fontSize($smallSize,$mediumSize,$largeSize){
  @include breakpoint(small){
    font-size: $smallSize;
  }
  @include breakpoint(medium){
    font-size: $mediumSize;
  }
  @include breakpoint(large){
    font-size: $largeSize;
  }
}
@mixin width($smallSize,$mediumSize,$largeSize){
  @include breakpoint(small){
    width: $smallSize;
  }
  @include breakpoint(medium){
    width: $mediumSize;
  }
  @include breakpoint(large){
    width: $largeSize;
  }
}
// /control

// template
a {
  transition: 0.3s ease;
}
img,svg {
  vertical-align: top;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
// /template

// common
.red {
  background: red;
  color: #fff;
}

.line10 { line-height: 1; }
.line15 { line-height: 1.5; }
.line18 { line-height: 1.8; }
.line20 { line-height: 2; }

.lines { line-height: 1.75; }
.linesS { line-height: 1.45; }

// common/font
.h1 { @extend h1; }
.h2 { @extend h2; }
.h3 { @extend h3; }
.h4 { @extend h4; }
.h5 { @extend h5; }
.h6 { @extend h6; }
.h6 { @include fontSize(13px,1rem,1rem); }

.s7 { @include fontSize(13px,13px,13px); }
.s8 { @include fontSize(11px,11px,11px); }
.s9 { @include fontSize(9px,9px,9px); }


// /common/font

.row {
  &.flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction:         row-reverse;
  }
}
.flex {
  &.flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction:         row-reverse;
  }
  &.align-middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;

//  margin: 0;
}
.flex .items {

  @include breakpoint(small only) {
    &.small-full {
      width: 100%;
    }
    &.small-half {
      width: 48%;
    }
  }
  @include breakpoint(medium) {

  }
}

.rowlex {
  @extend .flex;
//  @include width(100%,100%,1200px);
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint(large){
    padding: 0 2.5rem;
  }
  @media screen and #{breakpoint(large)} and #{breakpoint(80rem down)} {
    padding: 0 1rem;
  }
}

.transition {
  transition: 0.3s ease;
}
.duration {
  animation-duration: .3s;
}
.fixed {
  position: fixed;
}
.fix {
  position: fixed;
}
.hover {
  &:hover {
    opacity: .6;
  }
  &.default {
    color: $black;
  }
}
// /common

// width

.container {
  &.message {
    @include width(100%,100%,820px);
  }
  @include width(100%,100%,1000px);
  @include breakpoint(small){

  }
  @include breakpoint(medium down){
    padding: 0 10px;
  }
  margin: 0 auto;
}
.columnContainer {
  @include width(100%,64%,780px);
  margin: 0;
}
.galleyContainer {
  @include width(100%,auto,730px);
  margin: 0;
}
.subtextContainer {
  @include width(100%,100%,730px);
  margin: 0 auto;
}
.containerPhase {
  @include width(100%,604px,604px);
  margin: 0 auto;
}
.containerPriceN {
  @include width(100%,100%,750px);
  @include breakpoint(medium down){
    padding: 0 10px;
  }
  margin: 0 auto;
}
.containerPriceS {
  @include width(100%,100%,480px);
  @include breakpoint(medium down){
    padding: 0 10px;
  }
  margin: 0 auto;
}
.containerPrice {
  @include width(100%,100%,530px);
  @include breakpoint(medium down){
    padding: 0 10px;
  }
  margin: 0 auto;
}
.containerPriceMS {
  @include width(100%,100%,690px);
  @include breakpoint(medium down){
    padding: 0 10px;
  }
  margin: 0 auto;
}
.containerPriceM {
  @include width(100%,100%,790px);
  @include breakpoint(medium down){
    padding: 0 10px;
  }
  margin: 0 auto;
}
.containerPriceL {
  @include width(100%,100%,820px);
  @include breakpoint(medium down){
    padding: 0 10px;
  }
  margin: 0 auto;
}
.containerPriceLL {
  @include width(100%,100%,950px);
  @include breakpoint(medium down){
    padding: 0 10px;
  }
  margin: 0 auto;
}
.containerPriceXL {
  @include width(100%,100%,1000px);
  @include breakpoint(medium down){
    padding: 0 10px;
  }
  margin: 0 auto;
}

.galleyItem {
  @include width(100%,500px,500px);
  @include breakpoint(small){
    padding: 0;
    margin: 0;
  }
  background: #fff;
}
.containerCustom630 {
  @include width(100%,100%,630px);
  @include breakpoint(small){
    padding: 0;
  }
  margin: 0 auto;
}

// new template

// layout
$contents: 60px;
$contentsL: 40px;
$contentsM: 20px;
$contentsS: 10px;
.l-content {
  @include breakpoint(small){
    margin: $contents/2 auto 0;
  }
  @include breakpoint(medium){
    margin: $contents auto 0;
  }
  @include breakpoint(large){
    margin: $contents auto 0;
  }
}
.l-space {
  @include breakpoint(small){
    margin:  0 auto $contents/2;
  }
  @include breakpoint(medium){
    margin:  0 auto $contents;
  }
  @include breakpoint(large){
    margin:  0 auto $contents;
  }
}
.l-space-1 {
  @include breakpoint(small){
    margin: 0 auto $contentsL/2;
  }
  @include breakpoint(medium){
    margin: 0 auto $contentsL;
  }
}
.l-space-2 {
  @include breakpoint(small){
    margin: 0 auto $contentsM/2;
  }
  @include breakpoint(medium){
    margin: 0 auto $contentsM;
  }
}
.l-space-3 {
  margin: 0 auto $contentsS;
}
.l-side-middle {
  margin: 0 30px;
}

.l-center {
  margin: 0 auto;
}

.l-bottom-1 {
  @include breakpoint(small) {
    margin-bottom: $contentsL/2;
  }
  @include breakpoint(medium) {
    margin-bottom: $contentsL;
  }
}
.l-bottom-2 {
  @include breakpoint(small) {
    margin-bottom: $contentsM/2;
  }
  @include breakpoint(medium) {
    margin-bottom: $contentsM;
  }
}
.l-bottom-3 {
  margin-bottom: $contentsS;
}

.text-just {
  .inner {
    display: inline-block;
    margin: 0 auto;
    text-align: left;
  }
  text-align: center;
}

.text-left-small {
  @include breakpoint(small only){
    text-align: left;
  }
}
.text-center-small {
  @include breakpoint(small only){
    text-align: center;
  }
}

// extra
.googlemap {
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  position: relative;
  padding-bottom: 35%;
  width: 100%;
}
.youtube {
  iframe {
    max-width: 100%;
  }
}



