// 入力->確認->完了
$base: #ed959e;
$back: #ffe8ea;

$triangleSize: 20px 0 20px 25px;
$trianglePosition: -25px;

$triangleSizeS: 17px 0 17px 13px;
$trianglePositionS: -13px;

.form-phase {
  .phase {
    &:after {
      border-style: solid;
      border-width: $triangleSizeS;
      border-color: transparent transparent transparent $back;
      content: " ";
      display: block;
      height: 0;
      position: absolute;
      top: 0;
      right: $trianglePositionS;
      width: 0;
    }
    &.thanks {
      &:after {
        display: none;
      }
      width: 80px;
    }
    background: $back;
    line-height: 34px;
    position: relative;
    text-align: center;
    width: 70px;
  }
  display: flex;
  display:-webkit-box;
  display:-webkit-flex;

  justify-content: space-between;
  flex-pack: justify;
  -webkit-box-pack: justify;

}
.mw_wp_form_input {
  .phase.typing {
    &:after {
      border-style: solid;
      border-width: $triangleSizeS;
      border-color: transparent transparent transparent $base;
      content: " ";
      display: block;
      height: 0;
      position: absolute;
      top: 0;
      right: $trianglePositionS;
      width: 0;
    }
    background: $base;
    color: #fff;
  }
  .recruit-back-buttom {
    display: none;
  }
}
.mw_wp_form_confirm {
  .phase.confirm {
    &:after {
      border-style: solid;
      border-width: $triangleSizeS;
      border-color: transparent transparent transparent $base;
      content: " ";
      display: block;
      height: 0;
      position: absolute;
      top: 0;
      right: $trianglePositionS;
      width: 0;
    }
    background: $base;
    color: #fff;
  }
  .mw_wp_form_confirm_none {
    display: none!important;
  }
}
.mw_wp_form_complete {
  .phase.thanks {
    &:after {
      content: none;
      display: none;
    }
    background: $base;
    color: #fff;
  }
}


.typing {
  z-index: 3;
}
.confirm {
  z-index: 2;
}
.thanks{
  z-index: 1;
}


// テーブル
.submitContainer {
  input[type="submit"] {
    display: inline-block;
    margin: 10px 0;
  }
  .submitButtom {
//    @include box-shadow(1px 0px 2px rgba(0,0,0,.15));
    -webkit-appearance: none;
    &:active, &:hover {
      background: #444;
      color: #fff;
      cursor: pointer;
    }

    background: #fff;
    border: 1px solid #444;
    border-radius: 0;
    margin: 10px 0;
    padding: 10px 0;
    width: 100%;
  }
}


// 住所自動入力
$backB: #444;
$textB: #ffffff;

.mwform-checkbox-field.horizontal-item+.mwform-checkbox-field.horizontal-item {
  margin-left: 0;
}
.mwform-checkbox-field.horizontal-item {
  margin-right: 15px;
}
.zip {
  input {
    width: 30%;
  }
  #zip {
    &:active {
      background: $backB;
      color: $textB;
    }
//    @include transition(0.3s linear);
    border: 1px solid $backB;
    background: $textB;
    color: $backB;
    cursor: pointer;
    display: inline-block;
    padding: 3px 10px 5px;
    margin: 5px 0 0;
  }
}


@include breakpoint(large){
  .form-phase {
    .phase {
      &:after {
        border-width: $triangleSize;
        right: $trianglePosition;
      }
      &.thanks {
        width: 176px;
      }
      line-height: 40px;
      width: 150px;
    }
  }
  .mw_wp_form_input {
    .phase.typing {
      &:after {
        border-width: $triangleSize;
        right: $trianglePosition;
      }
    }
  }
  .mw_wp_form_confirm {
    .phase.confirm {
      &:after {
        border-width: $triangleSize;
        right: $trianglePosition;
      }
    }
  }
  .mw_wp_form_complete {
    .phase.thanks {
      &:after {
        content: none;
        display: none;
      }
    }
  }
}

