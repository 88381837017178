// control/color
$white: #ffffff;
$black: #333333;

$gray: #e0e0e0;
//$maincolor: #66BB6A;

$maincolor: #ec959d; // pink
$maincolor-light: #ffe8ea; // green 500
$maincolor-dark: #ed959e;; // pink darker

$subcolor: #ffe4c9; // cream
$accentcolor: #ff8f44; // orange
// /control/color

@mixin backfill(){
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

// /control

// template
body {
  background: url("../img/pattern.png") #fffdf7;
  color: $black;
  font-family: 'Noto Sans JP','ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'MS Pゴシック', 'MS PGothic', sans-serif;
  font-weight: 400;
  margin: auto;
  -webkit-text-size-adjust: 100%;

  @include fontSize(12px,12px,14px);
}

.cs-button {
  &.cs-main a {
    &:hover {
      background: $maincolor-dark;
      color: $white;
    }
    border: 2px solid $maincolor-dark;
    background: transparent;
    color: $maincolor;
    display: block;
    line-height: 1;
    padding: 15px 0;
    text-align: center;
    @include width(80%,430px,430px);
  }
  &.cs-main input {
    &:hover {
      background: $maincolor-dark;
      color: $white;
    }
    border: 2px solid $maincolor-dark;
    background: transparent;
    color: $maincolor;
    display: block;
    line-height: 1;
    padding: 15px 0;
    text-align: center;
    @include width(60%,300px,300px);
  }
  @include fontSize(12px,12px,20px);
  font-weight: 700;
}
.arrange {
  &::before {
    content: "※";
    margin-left: -1em;
  }
  padding-left: 1em;
}
.privacycheck {
  background: #fff0f2;
  box-sizing: border-box;
  border: 1px solid #cfcfcf;
  padding: 1em 1.5em;
  height: 180px;
  overflow: auto;
  width: 100%;
}
.checkbox {
  input[type="checkbox"] {
    &:checked::before {
      background-color: #ed959e;
      border-radius: 50%;
      border: solid 1px #ed959e;

      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      height: 12px;
      width: 12px;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #fff2f4;
    border-radius: 50%;
    border: solid 1px #cecece;
    margin: 0 .5em 0 0;
    position: relative;
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
//  padding-left: 1.5em;
}
.radio {
  input[type="radio"] {
    &:checked::before {
      background-color: #ed959e;
      border-radius: 50%;
      border: solid 1px #ed959e;

      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      height: 12px;
      width: 12px;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #fff2f4;
    border-radius: 50%;
    border: solid 1px #cecece;
    margin: 0 .5em 0 0;
    position: relative;
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}

// /template

// common

// all page
.color-white { color: $white; }
.color-main { color: $maincolor; }
.color-accent { color: $accentcolor; }
.color-red { color: #ff0000; }

.back-white { background: $white; }
.back-black { background: $black; }
.back-main { background: $maincolor; }
.back-light { background: $maincolor-light; }
.back-dark { background: $maincolor-dark; }

.cs-space-medium {
  margin: $spaceM auto;
}
.cs-space-large {
  margin: $spaceL auto;
}
.cs-space-small-right {
  margin-right: $spaceS;
}
.cs-space-caption {
  @include breakpoint(medium down){
    margin: 7px 0;
  }
  margin: 15px 0;
}
.cs-space-none {
  margin: 0;
}
.cs-center {
  margin: 0 auto;
}
.l-inner {
  margin: 0 auto $spaceM;
}

.diaIndent {
  &::before {
    content: "◆";
    position: absolute;
    left: 0;
    top: 0;
  }
  padding-left: 1em;
  position: relative;
}

.hoverA {
  &:hover {
    color: $black;
    opacity: .6;
  }
  color: $black;
  display: block;
}

.cs-small {
  display: none;
  @include breakpoint(small only){
    display: block;
  }
}
.cs-medium {
  display: none;
  @include breakpoint(medium only){
    display: block;
  }
}
.cs-xlarge {
  display: none;
  @include breakpoint(xlarge){
    display: block;
  }
}

.cs-sectionBgoff {
  @include breakpoint(small){
    margin: $contents/2 auto;
  }
  @include breakpoint(medium){
    margin: $contents auto;
  }
  @include breakpoint(large){
    margin: $contents auto;
  }

}
.cs-sectionBgon {
  &.white { background: $white; }
  &.gray { background: $gray; }
  &.green { background: $maincolor; }
  padding: $contents 0;
}
.cs-title {
  &.bordered {
    &::after {
      background: $maincolor-dark;
      content: " ";
      display: block;
      margin: 13px auto 0;
      height: 2px;
      width: 100px;
    }
  }
  &.marginBottom {
    @include breakpoint(small){
      margin-bottom: $contents/4;
    }
    @include breakpoint(large){
      margin-bottom: $contents/2;
    }
  }
  @include fontSize(22px,22px,40px);
  font-weight: 700;
  line-height: 1;
  text-align:center;
}
.cs-subtitle {
  &.linered {
    &::before {
      background: $maincolor-dark;
      box-sizing:content-box;
      border-top: 1px solid $maincolor-dark;
      border-bottom: 1px solid $maincolor-dark;
      content: " ";
      display: inline-block;
      height: 100%;
      position: absolute;
      top: 0;
      left: -23px;
      width: 5px;
    }
    border-bottom: 1px solid #cfcfcf;
    height: 32px;
    line-height: 32px;
    margin-left: 23px;
    position: relative;

    @include breakpoint(small){
      height: auto;
      line-height: 20px;
      padding-bottom: 5px;
    }
    @include breakpoint(medium){
      height: 32px;
      line-height: 32px;
      margin-left: 23px;
      padding-bottom: 0;
    }

  }


//  @include fontSize(12px,12px,18px);
  @include fontSize(14px,14px,18px);
  font-weight: 700;
  line-height: 1;
}
.sublast {
  margin-bottom: $contents;
}
.breadcrumb {
  a {
    &:hover {
      text-decoration: underline;
    }
    color: $black;
  }
  line-height: 1;
  @include breakpoint(small){
    margin: 10px auto 30px;
  }
  @include breakpoint(medium){
    margin: 17px auto 55px;
  }
  @include breakpoint(large){
    margin: 17px auto 55px;
  }
}
.textlink, .textlink a {
  &:hover {
    color: $accentcolor;
    text-decoration: none;
  }
  color: $accentcolor;
  @include fontSize(12px,12px,14px);
  line-height: 1.1;
  text-decoration: underline;

}
.link-inherit {
  &:hover {
    color: inherit;
  }
  color: inherit;
}
.cs-text-large {
  @include fontSize(14px,14px,18px);
  font-weight: 700;
  line-height: 1;
}
.smartleft {
  @include breakpoint(small){
    text-align: left;
  }
  @include breakpoint(medium){
    text-align: center;
  }
}

// /all page


// contact page
a.twobox-main {
  &:hover {
    background: $maincolor-dark;
    color: #fff;
  }
  border: 2px solid $maincolor-dark;
  color: $maincolor-dark;
  display: block;
  @include fontSize(12px,12px,20px);
  padding: 15px 0;
  @include width(85%,360px,360px);
  text-align: center;

  @include breakpoint(small){
    margin: 0 auto 20px;
  }
  @include breakpoint(medium){
    margin: 0 15px;
  }
}
div.twobox-main {
  @extend a.twobox-main;
  background: $maincolor-dark;
  border: 2px solid $maincolor-dark;
  color: #fff;
}
.contactCaption {
  @include breakpoint(small){
    margin: 0 auto 30px;
  }
  @include breakpoint(medium){
    margin: 30px auto 60px;
  }

  text-align: left;
  @include width(100%,480px,480px);
}
.contactTable {
  .single {
    &:first-child {
      border-top: 1px solid #d3d3d3;
    }
    border-bottom: 1px solid #d3d3d3;
  }
  .singleLeft {
    .must {
      color: red;
      @include breakpoint(small){
        margin-left: 1em;
      }
      @include breakpoint(medium){
        float: right;
        margin-left: 0;
      }
      font-weight: 400;
    }
    @include fontSize(12px,12px,14px);
    font-weight: 700;
    line-height: 1;
    @include width(100%,26%,26%);
    @include breakpoint(small){
      padding: 12px 0;
    }
    @include breakpoint(medium){
      padding: 25px 0 25px 68px;
    }
  }
  .caption {
    margin: 7px 0 0;
  }

  .singleRight {
    &.choice, &.calte {
      @include width(100%,100%,600px);
      @include width(100%,66%,66%);
    }
    &.textarea {
      @include width(100%,66%,586px);
    }
    @include breakpoint(small){
      padding: 0 0 20px;
      width: 100%;
    }
    @include breakpoint(medium){
      margin: 0 0 0 7.7%;
      padding: 20px 0;
      width: auto;
    }
    line-height: 1;
  }
}
.singleRightInner {
  > div {
    @include breakpoint(small only){
      width: 100%;
    }
  }
  .type {
    @include breakpoint(small){
      width: 100%;
    }
    @include breakpoint(medium){
      width: 20%;
    }
  }
  &:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }

  line-height: 24px;
  margin: 0 0 20px;
  width: 100%;
  @include breakpoint(small){

  }
  @include breakpoint(medium){
    border-bottom: 1px solid #d3d3d3;
    padding: 0 0 20px;
  }
  @include breakpoint(large){

  }
}
.cs-privacycheck {
  .privacycheck {
    margin: 10px 0 15px;
  }
  padding: 30px 0;
  @include width(100%,630px,630px);
  margin: 0 auto;
  @include breakpoint(small only){
    border-bottom: 1px solid #d3d3d3;
  }
}
// /contact page


// custom
$headerheight: 100px;
$headerheight: 130px;
.cs-header {
  // ヘッダーのズレを防ぐ大枠
  // もちろんfix前提
  height: $headerheight;
  position: relative;

  @include breakpoint(medium down){
    height: 40px;
    position: relative;
  }
}
.cs-headerInner {
  @include breakpoint(medium down){
    height: 40px;
    padding: 5px 10px 0;
  }
  height: $headerheight;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.cs-headerLogo {
  @include breakpoint(medium down){
    height: 30px;
    line-height: 30px;
    padding: 0;
  }
  a:hover {
    color: $maincolor;
  }
  padding: 34px 0;
}
.cs-headerRight {
  height: $headerheight;
  line-height: 64px;
  padding: 24px 0 0;
}

.megamenuArea:hover {
  .megamenu {
    a:hover {
      color: $black;
    }
    display: block;
    color: $black;

  }
  color: $maincolor;
}
.megamenu {
  background: #fff;
  display: none;
  position: absolute;
  top: $headerheight;
  left: 0;
  padding: 30px 0;
  width: 100%;

}
.cs-headerMenu {
  .cs-headerMenuItem {
    a {
      &:hover {
        color: $maincolor;
      }
      color: $black;
    }
    font-size: 14px;
    padding: 0 15px;
  }
  width: 720px;
  @media screen and #{breakpoint(large)} and #{breakpoint(75rem down)} {
    .cs-headerMenuItem {
      padding: 0 5px;
    }
    width: auto;
  }
}
.cs-headerMail .item {
  .icon {
    display: block;
    transform: rotate(90deg);
    float: left;
  }
  &.tel {
    img {
      vertical-align: middle;
    }
    color: $maincolor-dark;
    width: auto;
  }
  &.mail {
    background: url("../img/mail.png") $maincolor-dark;
    background-size: 50px;
    background-position: 100% center;
    background-repeat: no-repeat;
  }
  &.calendar {
    background: url("../img/calendar.png") $maincolor-dark;
    background-size: 50px;
    background-position: 100% center;
    background-repeat: no-repeat;
    text-decoration: none;
  }
  &:hover {
    opacity: .6;
  }
  border-radius: 10px;
  color: $white;
  display: block;
  text-align: right;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  width: 180px;

  margin: 0 0 0 20px;
}
.cs-smartmenu {
  .item {
    &.single {
      border-bottom: 1px solid #fff;
      line-height: 1;
      padding: 12px 0 18px 20px;
    }
    &.hasChild {
      line-height: 1;
      padding: 15px 0 18px 20px;
    }
    &.children {
      &::after {
        content: "▶";
        float: right;
        margin-right: 2em;
        transition: .3s ease;
      }
      &.open::after {
        transform: rotate(90deg);
      }
      background: #ffd4d8;
      border-bottom: 1px solid #fff;
      padding: 10px 0 10px 20px;
      line-height: 1;
    }
    &.grandson {
      background: #fff;
      padding-left: 44px;
      line-height: 28px;
      display: none;
    }
    &.mail a {
      color: $white;
      margin: 24px auto 0;
    }
    a {
      &:hover {
        color: inherit;
      }
      color: inherit;
    }
  }
  -webkit-animation-duration: .6s;
  animation-duration: .6s;

  background: #ffe8ea;
  position: fixed;
  top: 40px;
  right: 0;
  z-index: 9;
  padding-bottom: 15vh;
  width: 60%;
  height: 100vh;
  overflow: auto;
  display: none;
}



$vh: 60vh;
.cs-topview {
  .cs-topview-effect {
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
  }
  .cs-topview-catchcopy {
    .caption {
      display: block;
      @include fontSize(16px,16px,30px);
      text-align: left;
      line-height: 1;
      margin-top: 5px;
    }
    @include fontSize(18px,18px,50px);
    padding: 0 .5em;
    position: absolute;
    height: 100%;
    @include width(100%,100%,1000px);
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    margin: auto;
//    height: 2em;
//    line-height: 1.5em;
//    text-shadow: 1px 1px 1px #fafafa;
  }
//  background: url("../img/main.png");
//  background-repeat: no-repeat;
//  background-size: cover;
//  background-position: center;
  position: relative;
  text-align: center;

  @include breakpoint(small){
//    padding-top: 34%;
  }
  @include breakpoint(medium){
  }
  @include breakpoint(large){
//    height: $vh;
  }
}
.cs-globalMenuSp {
  background: rgba(0,0,0,.8);
  position: absolute;
  top: 65px;
}
.cs-toggleGlobalMenu {
  .menu-icon::after {
    background: $maincolor-dark;
    box-shadow: 0 7px 0 $maincolor-dark, 0 14px 0 $maincolor-dark;

//    top: 50%;
  }
  width: 90px;
}
.cs-globalMenu {
  .cs-globalMenuItem {
    a {
      &:hover {
        border: 1px solid #ccc;
      }
      border: 1px solid transparent;
      color: $maincolor-dark;
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &:last-child {
      border-right: none;
    }
  }
  padding: .5em 0;
}
.sticky {
  width: 100%;
}

// custom/pages

// index
.indexCampaign {
  .indexCampaignItem {
    &.campaign01 {
      background: url("../img/cam1.png");
      @include backfill;
      @include breakpoint(small){
        background-size: 100%;
      }
      @include breakpoint(large){
        padding: 45px 0 0 28px;
      }
    }
    &.campaign02 {
      background: url("../img/cam2.png");
      @include backfill;
      @include breakpoint(small){
        padding-left: 44%;
        background-size: 100%;
      }
      @include breakpoint(large){
        padding: 61px 0 0 280px;
      }
    }
    &.campaign03 {
      background: url("../img/cam3.png");
      @include backfill;
      @include breakpoint(small){
        padding-left: 44%;
      }
      @include breakpoint(large){
        padding: 60px 0 0 255px;
      }
    }
    &.campaign04 {
      background: url("../img/cam4.png");
      @include backfill;
      @include breakpoint(small){
        background-size: 100%;
      }
      @include breakpoint(large){
        padding: 36px 0 0 29px;
      }
    }
    .title {
      @include fontSize(19px,19px,32px);
      font-weight: 700;
      line-height: 1.2;
      margin: 0 0 10px;
    }
    .caption {
      @include fontSize(12px,12px,14px);
      line-height: 1.1;
    }
    height: 200px;
    position: relative;
    @include width(100%,100%,490px);

    @include breakpoint(small only){
      height: auto;
      padding: 21px 10px;
      width: 100%;
    }
    @include breakpoint(medium down){
      margin: 0 0 10px;
    }
  }
  @include breakpoint(small) {
    .hoverA {
      margin: 0 auto 20px;
    }
  }

  & + .indexCampaign {
    margin: 20px 0 40px;
    @include breakpoint(medium down){
      margin: 0;
    }
  }
}

.indexColoredFirst {
  @include breakpoint(medium down){
    margin: 0 0 5px;
  }
}
.indexColoredSecond .indexColoredListItem:last-child {
  margin-bottom: 0;
}
.indexColoredTitle {
  @include breakpoint(medium down){
    height: 30px;
    line-height: 30px;
  }
  &.pink { background: #fff2ff; }
  &.blue { background: #e6f2ff; }
  &.green { background: #e1fde1; }
  &.brown { background: #fff1e5; }

  @include fontSize(18px,18px,30px);
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
@mixin afterarrow($color){
  &::after {
    background: $color;
    color: $white;
    content: "▶";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    @include breakpoint(small){
      font-size: 16px;
      line-height: 30px;
      width: 16px;
    }
    @include breakpoint(medium){
      font-size: 20px;
      line-height: 60px;
      width: 20px;
    }
    @include breakpoint(large){

    }
  }
  border: 1px solid $color;
  position: relative;
}
.indexColoredList {
  .indexColoredListItem {
    @include breakpoint(medium down){
      svg,img {
        width: 40px;
      }
    }
    a {
      &:hover {
        opacity: .6;
      }
      color: $black;
    }
    background: $white;
    box-sizing: border-box;
    text-align: left;
    margin: 10px 0;
  }
  .indexColoredListItemCaption {
    @include fontSize(12px,12px,20px);
    line-height: 1;
    font-weight: 700;
    margin: 0 0 0 9px;
  }
  &.age .indexColoredListItem {
    @include afterarrow(#ffdeff);
  }
  &.face .indexColoredListItem {
    @include afterarrow(#cde6ff);
  }
  &.body .indexColoredListItem {
    @include afterarrow(#a3efa3);
  }
  &.other .indexColoredListItem {
    @include afterarrow(#ffe4ca);
  }
  margin: 0;
}

.indexNewsListItem {
  .date {
    @include fontSize(12px,12px,14px);
    @include fontSize(12px,12px,20px);
    line-height: 1.4;
    @include width(100%,100%,12%);
    margin: 0;
  }
  .title {
    @include fontSize(12px,12px,14px);
    line-height: 1.4;
    @include width(100%,100%,82%);
    @include breakpoint(medium down){
      margin: 5px 0 0;
    }
  }
  &:last-child {
    border-bottom: 1px solid #e1e1e1;
  }
  border-top: 1px solid #e1e1e1;
  @include breakpoint(small){
    padding: 8px 0 10px;
  }
  @include breakpoint(medium){

  }
  @include breakpoint(large){
    padding: 23px 0;
  }
}
.indexVoiceItem {
  @extend .indexNewsListItem;
  .date {
    @include width(100%,100%,10%);
  }
  .cat {
    background: $subcolor;
    text-align: center;
    @include width(100%,100%,8%);
  }
  .title {
    @include width(100%,100%,76%);
  }
}
.indexArticles {
  .left {
    @include width(100%,100%,490px);
  }
  .right {
    .articlesSingle:last-child {
      @include breakpoint(medium down){
        display: none;
      }
    }
    @include width(100%,100%,480px);
  }
}
.articlesSingle {
  .cat {
    @include fontSize(11px,11px,14px);
    line-height: 1;
  }
  .date {
    @include fontSize(11px,11px,14px);
    line-height: 1;
  }
  .title {
    @include fontSize(12px,12px,20px);
    line-height: 1.4;
  }
  .content {
    @include fontSize(11px,11px,14px);
    line-height: 1.4;
  }
}
.articlesSingle {
  &.large  {
    @include breakpoint(large){
      .cs-thumbnail {
        margin: 0 0 14px;
        width: 100%;
      }
      .cs-content {
        width: 100%;
      }
      .title {
        margin: 15px 0 6px;
      }
      .date {
        margin: 0;
        width: auto;
      }
      .flex {
        -webkit-flex-direction: row-reverse;
        flex-direction:         row-reverse;
      }
    }
  }
  margin: 0 0 30px;
  &:last-child {
    margin: 0;
  }
  @include breakpoint(medium down only){
    &, &:last-child {
      margin: 0 0 15px;
    }
  }

  .cat {
    background: $subcolor;
    padding: 5px 28px;
  }
  .cs-thumbnail {
    margin-right: 10px;
    @include width(30%,30%,190px);
  }
  .cs-content {
    @include width(66%,66%,280px);
  }
  .title {
    margin: 2px 0 0;
  }
  .date {
    margin: 5px 0 8px;
    @include width(auto,auto,100%);
  }
}
.articlesSingle.sub {
  .cs-thumbnail {
    @include width(30%,30%,260px);
    @include breakpoint(medium down){
      margin-right: 10px;
    }
    @include breakpoint(large){
      margin-right: 40px;
    }
  }
  .cs-content {
    @include breakpoint(small){

    }
    @include breakpoint(medium down){
      width: 66%;
    }
    @include breakpoint(large){
      width: calc(100% - 300px);
    }
  }
  .title {
    margin: 15px 0 6px;
  }
  .date {
    margin: 0 0 0 40px;
    width: auto;
  }
}
.articlesSingle.single {
  .cs-thumbnail {
    margin: 30px 0;
    @include width(auto,auto,auto);
  }
  .cs-content {
    width: 100%;
  }
  .title {
    margin: 30px 0;
    @include fontSize(16px,16px,30px);
  }
  .date {
    margin: 0 0 0 40px;
    width: auto;
  }
  @include breakpoint(small){

  }
  @include breakpoint(medium down){
    .cs-thumbnail, .title {
      margin: 15px 0;
    }
    .date {
      margin: 0 0 0 20px;
    }
  }

}

.indexItem {
  @include breakpoint(small only){
    .columns {
      margin: 0 0 10px;
    }
  }
  @include breakpoint(small){
    margin: $contents/2 auto;
  }
  @include breakpoint(large){
    margin: $contents+36 auto $contents;
  }
}
// /index

// monitor
.monitorList {
  li {
    @include fontSize(14px,14px,18px);
    font-weight: 700;
    margin: 18px 0;
  }
}
.monitorCaption {
  @include width(100%,100%,820px);
  margin: 24px auto 0;
}
.monitorLines {
  margin: 7px auto 0;
}
.monitorRequest {
  .lines {
    margin: 5px 0 20px;
  }
  .line10 {
    margin: 12px 0 0;
  }
  margin: 25px auto 40px;
}
// /monitor

// caution
.cautionText {
  margin: 20px auto;
}
// /caution

// fax
.fax-box {
  .fax-boxInner {
    border: solid 1px #ffcfa0;
    padding: 24px 0;
    text-align: center;
    line-height: 1;
  }
  .fax-boxNumber {
    @include fontSize(22px,22px,40px);
    font-weight: 700;
    line-height: 1;
    margin: 14px auto 0;
  }
  background: #fff1e2;
  border: solid 1px #ffcfa0;
  margin: 0 auto 60px;
  padding: 5px;
  @include width(100%,100%,410px);
}
.cs-subtitleIndent {
  padding-left: 1.2em;
}
.fax-img {
  margin: 20px auto 40px;
  text-align: center;
}
// /fax


// form
.cs-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.fill {
    @include width(100%,100%,100%);
  }
  &.fit {
    @include width(100%,450px,450px);
  }
  &.irregular {
    @include width(100%,100%,450px);
  }

  &:focus {
    background-color: #fff1f2;
    border: solid 1px #cecece;
  }

  background-color: #fff1f2;
  box-shadow: none;
  border: solid 1px #cecece;
  font-size: inherit;
  margin: 0;
  height: auto;
  width: auto;
}
.cs-textarea {
  &:focus {
    background-color: #fff1f2;
    border: solid 1px #cecece;
  }
  background-color: #fff1f2;
  box-shadow: none;
  border: solid 1px #cecece;
  font-size: inherit;
  height: 135px;
  margin: 0;
  width: 100%;
}
.cs-age {
  .cs-input {
    margin-right: .5em;
    width: 150px;
  }

}
.cs-media {
  label,input {
    display: inline-block;
  }
  label {
    @include breakpoint(small){
      margin: 0 0 1em;
    }
    @include breakpoint(medium){
      margin: 0 20px 25px 0;
    }
  }

  .mwform-checkbox-field {
    @include breakpoint(small){
      display: inline-block;
      margin: 0 0 1em;
    }
    @include breakpoint(medium){
      margin: 0 20px 25px 0;
      margin: 0 0 25px;
    }
  }

}
.cs-simple {
  label {
    @include breakpoint(small){
      display: inline-block;
      margin: 0 1em 0 0;
    }
    @include breakpoint(medium){
//      margin: 0 20px 25px 0;
    }
    @include breakpoint(large){

    }
  }
}
.cs-form-phase {
  @include fontSize(14px,14px,18px);
}
.cs-formCaption {
  @include breakpoint(small){
    margin: 10px auto 20px;
  }
  @include breakpoint(medium){
    margin: 60px auto 40px;
  }
  @include breakpoint(large){

  }

  text-align: center;
}
.cs-formSubmit {
  input {
    margin: 0 30px;
  }
}

// parts
.hopedate {
  &.time {
    .calendar {
      margin: 0 30px 0 20px;
    }
  }
  .calendar {
    margin: 0 0 0 20px;
    padding: .5em 0;
  }
  .cs-input {
    @include width(100%,260px,260px);
  }
}
.calendar {
  .fa {
    font-size: 24px;
    line-height: 1;
    margin-right: 12px;
  }
  color: #ed959e;
  text-decoration: underline;
  @include breakpoint(small){
    display: none;
  }
  @include breakpoint(medium){
    display: block;
  }
  @include breakpoint(large){

  }
}
.selectTime {
  &::after {
    content: "頃";
    display: inline-block;
    position: absolute;
    top: .8em;
    right: -1.5em;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #fff1f2;
    box-shadow: none;
    border: solid 1px #cecece;
    font-size: inherit;
    margin: 0;
    height: auto;
    width: 100%;
  }
  position: relative;
  width: 112px;
}
// /form


.cs-slide_back {
  .cs-slide_back_items {
    &.active {
      display: block;
    }
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    animation-duration: 4s;
  }
  img {
    @include breakpoint(small){
      height: 100%;
      top: 0;
      left: 50%;
      transform: translate(-50%,0);
      max-width: none;
    }
    @include breakpoint(large){
      height: auto;
      max-height: none;
      transform: translate(0,-50%);
      left: 0;
      top: 50%;
      width: 100%;
    }
    position: absolute;
  }
  position: relative;
  height: 100%;
  width: 100%;
}
.cs-slide_text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 200px;
  width: 100%;
}
.cs-catchcopy {
  h2 {
    border-radius: 5px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.6), 3px -3px 3px rgba(255, 255, 255, 0.6),-3px 3px 3px rgba(255, 255, 255, 0.6), -3px -3px 3px rgba(255, 255, 255, 0.6);
    padding: 0 10px;
  }
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
}
#slide_minim {
  .slide_item {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
    height: 200px;
    width: 80%;
  }
  height: 100%;
  position: relative;
  width: 100%;
}


.cs-sub-header {
  background: url("../img/submain.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  width: 100%;

  @include breakpoint(small){
    height: 100px;
  }
  @include breakpoint(medium){
    height: 200px;
  }
  @include breakpoint(large){
    height: 300px;
  }
}
.cs-sub-content {
  position: relative;
  margin: 0 auto 120px;
}

// column
.pagenation {
  a,span {
    border: 1px dotted $maincolor;
    color: $black;
    padding: .1em 1em;
    margin: 0 1em;
  }
  a:hover ,span {
    background: $maincolor;
    color: $white;
  }
  @include breakpoint(small){

  }
  @include breakpoint(medium down){
    a,span {
      margin: .5em 1em;
    }
  }
}
.pagenationSingle {
  a,span {
    color: $black;
    padding: .1em 1em;
    margin: 0 1em;
    text-decoration: underline;
  }
  a:hover ,span {
    text-decoration: none;
  }
  @include breakpoint(medium down){
    a,span {
      margin: .5em 1em;
    }
  }
}

// message
.message {
  .left {
    width: 27%;
  }
  .right {
    width: 70%;
  }
  @include breakpoint(small only){
    .left {
      width: 100%;
    }
    .right {
      margin: 15px auto 0;
      width: 100%;
    }
  }
}

// sidebar
.sidebar {
  .sidebarInner {
    border: 1px solid $maincolor-dark;
  }
  .parent {
    background: $maincolor-dark;
    color: $white;
    @include fontSize(14px,14px,18px);
    line-height: 1;
    padding: 12px 20px;
    margin: 0;
  }
  .children {
    background: $maincolor-light;
    line-height: 1;
    padding: 8px 20px;
  }
  .grandson {
    background: $white;
    padding: 0 20px;
    line-height: 30px;
  }

  @include breakpoint(small only){
    .parent, .grandson {
      text-align: center;
    }
    .children {
      &::after {
        content: ">";
        float: right;
      }
      border-bottom: 1px solid $maincolor;
    }
    .active {
      &::after {
        transform: rotate(90deg);
      }
      & + .grandson {
        display: block;
      }
    }
    .grandson {
      display: none;
    }
    margin-bottom: 30px;
    padding: 0;
    width: 100%;
  }
  @include breakpoint(medium){
//    padding: 0;
//    width: 210px;
  }
  @include breakpoint(large){
  }
  padding: 0;
  margin: 0;
  width: 210px;
}
.sidebarCate {
  .sidebarCateInner {
    border: 1px solid $maincolor;
  }
  .parent {
    background: $maincolor-dark;
    color: $white;
    @include fontSize(14px,14px,18px);
    line-height: 1;
    padding: 12px 0;
    margin: 0;
    text-align: center;
  }
  .children {
    &:last-child {
      border-bottom: none;
    }
    background: $white;
    border-bottom: 1px solid $maincolor-light;
    line-height: 30px;
    padding: 0 0 0 20px;
  }

  @include breakpoint(small only){
    margin: 0 auto 30px;
    width: 100%;
  }
  @include breakpoint(medium){
    width: 180px;
  }
  @include breakpoint(large){

  }
}

.galleyLatest {
  .arrow {
    &.prev .icon { display: block; transform: rotate(-90deg); }
    &.next .icon { display: block; transform: rotate(90deg); }
    background: $maincolor-dark;
    color: $white;
    height: 140px;
    line-height: 140px;
    width: 20px;
  }
  .galleyLatestFrame {
    @include breakpoint(small){
      width: 220px;
    }
    @include breakpoint(medium){

    }
    @include breakpoint(large){
      width: 650px;
    }
    position: relative;
    overflow: hidden;
  }
  .galleyLatestInner {
    .galleyItem {
      &:last-child {
        margin: 0;
      }
      float: left;
      line-height: 0;
      margin-right: 30px;
      height: 102px;
      width: 140px;

    }
    .change {
      width: 50%;
    }
    @include width(1330px,1330px,1330px);
    padding: 20px 0;
    position: relative;
    left: 0;
  }
  margin: 30px auto 60px;
  background: $maincolor-light;
  overflow: hidden;
  width: 100%;
}
.galleySlick {
  .galleySlickItem {
    .galleySlickItemBA {
      height: 88px;
    }
    .galleySlickItemImg {
      width: 65px;
    }
    background: $white;
    height: 102px;
    margin: 0 15px;
  }
  .slick-list {
    width: 650px;
    width: 680px;
    margin: 0 auto;
  }
  .slick-prev {
    &:before {
      content: "▼";
      font-size: 14px;
      opacity: 1;
      display: block;
      transform: rotate(90deg);
    }
    background: #ed959e;
    left: 0;
    height: 100%;
    width: 20px;
  }
  .slick-next {
    &:before {
      content: "▼";
      font-size: 14px;
      opacity: 1;
      display: block;
      transform: rotate(-90deg);
    }
    background: #ed959e;
    right: 0;
    height: 100%;
    width: 20px;
  }
  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    background: #ed959e;
  }
  @include breakpoint(small only){
    .galleySlickItem {
      height: 102px;
      height: auto;
    }
    .slick-list {
      width: 84%;
    }
  }
  background: #ffe8ea;
  padding: 20px 0;
}

.galleryPhoto {
  .galleyItem {
    .galleyItemhalf {
      width: 48%;
    }
    margin-bottom: 30px;
  }
  time {
    background: transparent;
    display: block;
    line-height: 1;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  margin: 60px auto 40px;
}


// news
.newsSingle {
  time {
    color: $maincolor;
    @include fontSize(12px,12px,14px);
  }
  h3 {
    @include fontSize(16px,16px,30px);
    border-bottom: 1px solid #e6e6e6;
  }

  border-left: 8px solid $maincolor;
  padding-left: 10px;
  font-weight: 700;
  line-height: 1;
}
// voice
.voiceList {
  .single {
    &:after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 23px 23px;
      border-color: transparent transparent $maincolor-light transparent;

      position: absolute;
      right: 0;
      bottom: 0;
    }
    .cat {
      background: $subcolor;
      padding: 0 20px;
      margin-right: 10px;
    }
    background: $white;
    padding: 20px;
    border: 1px solid $maincolor-light;
    @include width(100%,100%,49%);
    position: relative;
    margin: 0 0 20px;
  }
}

// clinic
.clinicProfile {
  border-bottom: 1px solid $maincolor;
  margin: 20px 0 10px;
  padding: 0 0 20px;
}

// price
.pricelink {
  .items {
    a {
      &:hover, &.active {
        background: $maincolor;
        color: $white;
      }
      @include fontSize(12px,12px,20px);
      border: 2px solid $maincolor;
      color: $maincolor;
      display: block;
      padding: 15px 0;
      text-align: center;
      width: 160px;
    }
  }

  &.face .face a { background: $maincolor; color: $white; }
  &.body .body a { background: $maincolor; color: $white; }
  &.siwa .siwa a { background: $maincolor; color: $white; }
  &.skin .skin a { background: $maincolor; color: $white; }
  &.laser .laser a { background: $maincolor; color: $white; }
  &.other .other a { background: $maincolor; color: $white; }

  @include breakpoint(small only){
    .items {
      a {
        width: 100%;
      }
      margin: 0 0 15px;
      width: 48%;
    }
  }
}






// footer
.cs-footer {
  @include breakpoint(small){
    padding: $spaceM/2 0 1px;
  }
  @include breakpoint(medium){
    padding: $spaceM 0 1px;
  }
  @include breakpoint(large){

  }
}
.footerContents {
  .officialsite a {
    &:hover {
      background: $maincolor-dark;
      color: #fff;
    }
    border: 2px solid $maincolor-dark;
    color: $maincolor;
    display: block;
    @include fontSize(12px,12px,20px);
    line-height: 1;
    padding: 15px 26px;
    text-align: center;
  }

  @include breakpoint(small){

  }
  @include breakpoint(medium down){
    .logo {
      width: 120px;
    }
    .officialsite a {
      padding: 7px 13px;
    }
  }
  @include breakpoint(large){

  }
}
.copyright {
  @include breakpoint(medium down){
    margin: 10px auto 20px;
  }
  @include fontSize(6px,6px,12px);
//  margin: 20px auto 40px;
  margin: 10px auto 20px;
  line-height: 1;
  text-align: center;
}
.footerNav {
  a {
    color: $maincolor;
    display: block;
//    margin: 0 1em;
    padding: 0 1em;
  }
  .footerNavItem {
    &:last-child {
      border-right: none;
    }
    border-right: 1px solid $maincolor;
  }

}
// /footer


// /custom/pages

//
.containerPhase {
  margin-bottom: 30px;
}
.ion-ios-email-outline {
  color: $maincolor-dark;
  font-size: 26px;
  line-height: 30px;
}
//

#clinic-near {
  option {
    &:nth-child(1), &:nth-child(2),&:nth-child(14),
    &:nth-child(16), &:nth-child(24),&:nth-child(28),
    &:nth-child(31)
    {
      font-weight: bold;
    }
  }
}

// /custom


.frame-830 {
  max-width: 830px;
}
.frame-790 {
  max-width: 790px;
}
.frame-760 {
  max-width: 760px;
}
.frame-730 {
  max-width: 730px;
}
.frame-600 {
  max-width: 600px;
}
.checkmark {
  background: url("../img/checkmark.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 18px auto;
  padding-left: 2em;
}
.qanda {
  .single {
    .inner {
      max-width: 600px;
      margin: $contentsM auto;
    }
    .que {
      &:before {
        content: "Q.";
        font-size: 20px;
        color: $maincolor;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
      @extend .bold;
      position: relative;
      padding: .4em  0 0 2em;
      margin: 0 0 10px;
    }
    .ans {
      &:before {
        content: "A.";
        font-size: 20px;
        color: $accentcolor;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
      position: relative;
      padding-left: 2em;
      padding: .4em  0 0 2em;
      margin: 0;
    }
    &:first-child {
      border-top: 1px solid #ccc;
    }
    border-bottom: 1px solid #ccc;

  }
}
.example-kuma-0 {
  .items {
    @include breakpoint(medium) {
      margin-right: $spaceM;
    }
  }
}

.example-kuma-1 {
  @include breakpoint(small only) {
    .left {
      margin: 0 0 $spaceS;
    }
  }
  @include breakpoint(medium) {
    .left {
      margin-right: $spaceM;
      width: 45%;
    }
    .right {
      width: 45%;
    }
  }
}
.example-kuma-2 {
  @include breakpoint(small only) {
    .items {
      margin: 0 0 $spaceS;
      width: 46%;
    }
  }
  @include breakpoint(medium) {
    .items {
      width: 22%;
    }
  }
}

.bust-announce {
  .left {
    width: 60%;
  }
}



